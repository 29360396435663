import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Facebook, YouTube } from '@mui/icons-material';

export default function Hero(props) {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage: 'linear-gradient(180deg, rgba(255,202,24,0.25), #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Button onClick={() => {window.location.href = "/pioneers-dashboard"}} style={{padding: "2% 4%", border: "5px solid #FECA1C", borderRadius: "30px", fontWeight: "900", fontSize: "120%", color: "#FECA1C", fontFamily: "system-ui"}}>Click here to open the Pioneers dashboard</Button>
        <br/><br/>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          {props.silentJim && <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
              marginBottom: "3vh",
            }}
          >
            <span>Special Discount from <span style={{color: "#FFCA18"}}>
              <br/>
              SilentJim<br/>
              </span>
              <strong>50%</strong> OFF For Life!<br/>
              <img style={{borderRadius: "200px", width: "125px", marginBottom: "-1.2vw"}} alt="jim" src="https://www.nepeto.com/assets/jim.jpg"/>
              </span>
          </Typography>}
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
              zIndex: "99"
            }}
          >
            <span>Product Analysis for <span style={{color: "#FFCA18"}}>{" "}Walmart</span></span>
          </Typography>
          <img onClick={(e) => e.target.style.display = "none"} src="alerts.png" alt="Marter Alerts" className='marterAlerts' style={{width: "16vw", margin: "auto", borderRadius: "30px", position: "fixed", right: "1vw"}} />
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={3}
            useFlexGap
            style={{margin: "auto", display: "inline-flex"}}
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}>
            <Button variant='outlined' onClick={() => window.open("https://www.facebook.com/share/g/hr6idZ8zRkis9FKE/")}>Join our&nbsp;<Facebook/>&nbsp;Community</Button>
            <Button variant='outlined' style={{color: "orange", borderColor: "orange"}} onClick={() => window.open("https://www.marteroa.com/")}><strong style={{marginTop: "2px"}}>Go to</strong>&nbsp;&thinsp;<img src="/moa.png" style={{width: "84px", mixBlendMode: "multiply"}}/>&nbsp;</Button>
            <Button variant='outlined' style={{color: "red", borderColor: "red"}} onClick={() => window.open("https://www.youtube.com/playlist?list=PLevLP9Na0UzlfFKAYS0pPNqJlbKihTdhF")}><YouTube/>&nbsp;How to use&nbsp;<img style={{width: "18px"}} src="/logo512.png"/></Button>            </Stack><br/><br/>
            Calculate profitability and gain insights. Use our historical data and sales estimations to make the best decisions.
            <br/>
            Helping <strong>Walmart Sellers</strong> save time and money effortlessly.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              name="email"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Enter your email address',
              }}
              onChange={(e) => props.setEmail(e.target.value)}
            />
            <Button variant="contained" style={{backgroundColor: "rgb(230,175,0)"}} onClick={(e) => props.signUp(e)}>
              Start your Free Trial
            </Button>
          </Stack>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start&quot; you agree to our&nbsp;
            <Link href="/terms.pdf" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
        <img
          id="image"
          className='zoom-img'
          style={{width: "85%", marginTop: "4vh", borderRadius: '10px', outline: '1px solid',
          outlineColor: alpha('#BFCCD9', 0.5),
          boxShadow: `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`}}
          src="/demo.gif"
          onMouseEnter={(e) => e.target.src = "/marter2.png"}
          onMouseLeave={(e) => e.target.src = "/demo.gif"}
          onTouchStart={(e) => e.target.src = "/marter2.png"}
          onTouchEnd={(e) => e.target.src = "/demo.gif"}
          alt="Marter"
        />
      </Container>
    </Box>
  );
}
