import * as React from "react";
import { Box, Button, Stepper, Step, StepLabel, Typography, Grid, Paper, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Check, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

export default function PioneersStart() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [hover, setHover] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [addNepeto, setAddNepeto] = React.useState(false);
  const [addWebinars, setAddWebinars] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState("Annual");

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const affiliate = urlParams.get('a') ?? "none"

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      if (affiliate !== "none") {
        fetch("https://server.nepeto.com/marter_had_trial/" + email + "/" + affiliate + "/", {
          mode: "cors",
        })
      }

      let productId = ""
      if (selectedPlan === "Lifetime") {
        productId = "108836"
      } else if (selectedPlan === "Annual") {
        productId = addNepeto ? "108839" : "108840"
      } else {
        productId = addNepeto ? "108841" : "108842"
      }

      if (addWebinars) {
        productId += "&products%5B2%5D%5Bid%5D=108837"
      }

      if (productId) {
        window.location.href = `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=${productId}&page-template=19169&language=en&currency=USD&billing-email=${email}`
      } else {
        alert("Error! Contact us at info@getmarter.com for help.")
      }
    } else {
      if (affiliate === "none") {
        alert("Bundle Sign-Up is Closed")
        fetch("https://server.nepeto.com/email", {
          "headers": {
            "accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
            "accept-language": "en-US,en;q=0.9,he;q=0.8,ru;q=0.7",
            "cache-control": "max-age=0",
            "content-type": "application/x-www-form-urlencoded",
          },
          "referrer": "https://www.nepeto.com/",
          "body": `name=${encodeURIComponent(email)}&email=${encodeURIComponent(email)}&message=Pioneer&_gotcha=&what_is_it=contact`,
          "method": "POST",
          "mode": "cors",
        });
        window.location.href = "/pioneers-dashboard";
      } else {
        if (!email) {
          alert("Email is required.")
        } else {
          setActiveStep((prev) => prev + 1);
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const plans = [
    { name: "Monthly", price: addNepeto ? 99 : 65, original: addNepeto ? 337 : 238, recc: "mo" },
    { name: "Annual", price: addNepeto ? 995 : 695, original: addNepeto ? 4044 : 2856, recc: "year" },
    { name: "Lifetime", price: 1950, },
  ];

  const items = [
    { logo: "/logos/marter.png", org: 48 },
    { logo: "/logos/alerts.png", org: 10 },
    { logo: "/logos/moa.png", org: 59 },
    { logo: "/logos/falcon.png", org: 59 },
    { logo: "/logos/histomart.png", org: 18 },
    { logo: "/logos/aniseller.png", org: 29 },
    { logo: "/logos/nepetoraoa.png", org: 15 },
  ];

  const steps = ["Enter Email", "Select Programs", "Choose Plan", "Add-ons"];

  return (<>
    <Typography
      variant="h2" // Bigger and bolder
      sx={{
        fontWeight: "bold",
        background: "linear-gradient(120deg, #FECA1C , #ffcc00, #FECA1C ,rgb(189, 145, 0) )",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        display: "inline-block",
        position: "relative",
        marginTop: isMobile ? "15%" : "5%",
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: "-5px",
          left: 0,
          width: "100%",
          height: "10px",
          background: "linear-gradient(120deg, #FECA1C , #ffcc00, #FECA1C ,rgb(181, 139, 0) )",
          transform: "skewY(-0deg)",
        },
      }}
    >
      The {affiliate === "crazyflippinmom" && "Happy Birthday "}Pioneers Bundle
    </Typography>

    <Box sx={{ width: "80%", margin: "auto", mt: 5 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 4 }}>
        {activeStep === 0 && (
          <Box>
            <Typography variant="h6">Step 1: Enter Your Email</Typography>
            <input onBlur={(e) => setEmail(e.target.value)} type="email" placeholder="Enter email" style={{ padding: 10, width: isMobile ? "75%" : "30%", marginTop: 10 }} />
          </Box>
        )}

        {activeStep === 1 && (
          <Box sx={{ width: isMobile ? "100%" : "50%", marginTop: isMobile ? "20px" : "0", margin: "auto" }}>
            <Grid container spacing={3} style={{ marginLeft: "8%" }}>
              {items.map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Paper
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <Check />&emsp;
                    <img
                      src={item.logo}
                      style={{
                        width: isMobile ? "50%" : "40%",
                        mixBlendMode: "multiply",
                      }}
                    />
                  </Paper>
                </Grid>
              ))}
              <Grid item style={{ marginTop: "2%" }}>
                <Paper
                  onClick={() => setAddNepeto(p => !p)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "none",
                    cursor: "pointer",
                    position: "relative",
                    padding: "10px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    transform: !hover ? "scale(1.05)" : "scale(1)",
                    boxShadow: !hover ? "0px 4px 10px rgba(0,0,0,0.2)" : "none",
                    backgroundColor: addNepeto ? "lightgreen" : "transparent",
                    padding: "10px"
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  {/* Left Arrow */}
                  <Typography
                    sx={{
                      position: "absolute",
                      left: "-30px",
                      fontSize: "3rem",
                      color: "gray",
                      marginLeft: "-10%",
                      opacity: !hover ? 1 : 0,
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    →
                  </Typography>

                  {addNepeto ? <CheckBox /> : <CheckBoxOutlineBlank />} &emsp;
                  <img
                    src={"/logos/nepeto.png"}
                    style={{
                      width: isMobile ? "50%" : "35%",
                      mixBlendMode: "multiply",
                    }}
                  />
                  <Typography
                    sx={{
                      marginLeft: "10%",
                      color: "red",
                      textAlign: "left",
                      fontSize: isMobile ? "15px" : ".75vw",
                    }}
                  >
                    <span style={{ color: "rgb(2, 153, 30)" }}>Pioneer Member: <strong>65% OFF !!!</strong></span>
                  </Typography>

                  {/* Right Arrow */}
                  <Typography
                    sx={{
                      position: "absolute",
                      right: "-30px",
                      fontSize: "3rem",
                      color: "gray",
                      opacity: !hover ? 1 : 0,
                      marginRight: "-10%",
                      transition: "opacity 0.3s ease",
                    }}
                  >
                    ←
                  </Typography>
                </Paper>

                {/* Click Here Text */}
                <Typography
                  sx={{
                    textAlign: isMobile ? "left" : "center",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    marginTop: "10px",
                    color: "rgb(2, 153, 30)",
                    cursor: "pointer",
                    transition: "color 0.3s ease",
                    "&:hover": {
                      color: "darkgreen",
                    },
                  }}
                  onClick={() => setAddNepeto(p => !p)}
                >
                  Click here to add Nepeto 3.0 Master Plan to your bundle
                </Typography>
                <br />
              </Grid>
            </Grid>
          </Box>
        )}

        {activeStep === 2 && (
          <Box>
            <Typography variant="h6">Step 3: Choose Your Plan</Typography>
            <Typography variant="h4">{selectedPlan} Plan</Typography>
            <br />
            <Grid container spacing={2}>
              {plans.map((plan, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Paper
                    sx={{
                      padding: 3,
                      textAlign: "center",
                      cursor: "pointer",
                      transition: "all 0.3s",
                      boxShadow: selectedPlan === plan.name ? "0px 4px 10px rgba(0,0,0,0.3)" : (!plan.recc ? "0px 0px 15px 0px yellow" : "none"),
                      border: selectedPlan === plan.name ? "5px solid green" : "1px solid lightgray",
                      borderRadius: 2,
                      "&:hover": { boxShadow: "0px 4px 15px rgba(0,0,0,0.3)" },
                    }}
                    onClick={() => setSelectedPlan(plan.name)}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {plan.name} Plan
                    </Typography>
                    <Typography sx={{ fontSize: "350%", color: "green", mt: 1 }}>
                      ${plan.price}{plan.recc ? <span style={{ fontSize: "65%" }}>/{plan.recc}</span> : <span style={{ fontSize: "50%" }}><br />ONE TIME PAYMENT</span>}
                    </Typography>
                    {plan.original && <Typography sx={{ textDecoration: "line-through", color: "red", fontSize: "200%" }}>
                      ${plan.original}<br /><br />
                    </Typography>}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {activeStep === 3 && (
          <Box>
            <Typography variant="h6">Step 4: Add-ons</Typography>
            <Paper
              sx={{
                padding: 2,
                cursor: "pointer",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                boxShadow: "none",
                margin: "auto",
                display: "inline-flex"
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      name="addNepeto"
                      color="primary"
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "150%" }}>
                      Pioneers T-Shirt <span style={{ color: "green" }}>FREE</span>.
                      <br />
                      <img src="/logos/tshirt.jpg" alt="TShirt" style={{ width: "30%" }} />
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addWebinars}
                      onChange={() => setAddWebinars(p => !p)}
                      name="addNepeto"
                      color="primary"
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "150%", textAlign: "left", marginLeft: "auto" }}>
                      Webinar tickets:<br />
                      Tutorials on how to use<br />
                      the tools in the best way for only{" "}
                      <span style={{ color: "green" }}>$9.99</span>.
                    </Typography>
                  }
                />
              </Box>
            </Paper>

          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 4 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: 600, width: "100%" }}>
            <Button
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ padding: "6px 16px" }}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={activeStep === steps.length}
              sx={{ padding: "6px 16px" }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Box>
        <br /><br /><br />
      </Box>
    </Box>
  </>
  );
}
