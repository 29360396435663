import * as React from "react";
import { Box, Avatar, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Pioneers() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const items = [
    { logo: "/logos/marter.png", title: "Marter", subtitle: "WM Product Analysis", org: 48 },
    { logo: "/logos/alerts.png", title: "Marter Alerts", subtitle: "WM Product Tracker Alert", org: 10 },
    { logo: "/logos/moa.png", title: "MOA", subtitle: "WM Sourcing Software", org: 59 },
    { logo: "/logos/falcon.png", title: "Inventory Falcon", subtitle: "Manage WM Inventory", org: 59 },
    { logo: "/logos/histomart.png", title: "Histomart", subtitle: "WM Historical Data", org: 18 },
    { logo: "/logos/aniseller.png", title: "Aniseller", subtitle: "WM/AMZ CSV Scanner", org: 29 },
    { logo: "/logos/nepeto.png", title: "Nepeto RA OA", subtitle: "RA Mobile App + Chrome ext.", org: 15 },
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        padding: isMobile ? "10px" : "5vh",
        marginTop: isMobile && "10%"
      }}
    >
      {/* Left Section */}
      <Box
        sx={{
          width: isMobile ? "100%" : "60%",
          textAlign: "left",
          padding: 2,
        }}
      >
        <Typography
          variant={isMobile ? "h4" : "h2"}
          sx={{
            fontSize: isMobile ? "6vw" : "3.8vw",
            marginBottom: 2,
          }}
        >
          <Box display="flex" alignItems="center" flexDirection={isMobile ? "column" : "row"}>
            <Box sx={{ maxWidth: isMobile ? "100%" : "60%" }}>
              Instead of paying
              <span style={{ color: "red", textDecoration: "line-through"}}> $238</span> for these tools, get the Pioneers discounted bundle
              for just <span style={{ color: "rgb(2, 153, 30)", fontWeight: "600" }}>$65</span>.
            </Box>
            <img
              src="/logos/tshirt.jpg"
              alt="T-Shirt"
              style={{
                width: isMobile ? "60%" : "35%",
                marginTop: isMobile ? "15px" : "0",
                mixBlendMode: "multiply"
              }}
            />
          </Box>
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            width: isMobile ? "100%" : "auto",
            backgroundColor: "#0077B6",
            color: "#fff",
            padding: "16px 32px",
            fontSize: "18px",
            fontWeight: "bold",
            letterSpacing: "1px",
            borderRadius: "8px",
            textTransform: "none",
            animation: "pulse 2s infinite ease-in-out",
            "&:hover": {
              backgroundColor: "#005F73",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              transform: "scale(1.05)",
            },
          }}
          onClick={() => {
            window.location.href =
              "/pioneers/start";
          }}
        >
          Secure your spot as a Pioneer by clicking here!
        </Button>
      </Box>

      {/* Right Section */}
      <Box sx={{ width: isMobile ? "100%" : "40%", marginTop: isMobile ? "20px" : "0" }}>
        <Grid container spacing={1}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <img
                  src={item.logo}
                  style={{
                    width: isMobile ? "15%" : "25%",
                    marginRight: isMobile ? "5%" : "8%",
                    mixBlendMode: "multiply",
                  }}
                />
                <Stack>
                  <Typography variant="h6" style={{textAlign: "left"}}>{item.title}</Typography>
                  <Typography variant="body2" color="textSecondary" style={{textAlign: "left"}}>
                    {item.subtitle}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    marginLeft: "auto",
                    color: "red",
                    textAlign: "left",
                    fontSize: isMobile ? "12px" : "inherit",
                  }}
                >
                  Original Price: <span style={{textDecoration: "line-through"}}>${item.org}</span>
                  <br />
                  <span style={{ color: "rgb(2, 153, 30)" }}>Pioneer Member: $9.28</span>
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
