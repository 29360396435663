import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';

export default function AffDashboard(props) {
  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const defaultTheme = createTheme({ palette: "light" });

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Check if the 'affiliate' parameter exists in the query string
    if (urlParams.has("id")) {
      const getAff = async () => {
        fetch("https://server.getmarter.com/get_aff/" + urlParams.get('id') + "/", {
          mode: "cors",
        }).then((response) => response.json()).then((data) => {setEmails(data); setLoading(false)});
      };
      getAff();
    }
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={'light'} setEmailModal={props.setEmailModal} />
      <Box>
        <h1 style={{paddingTop: "15vh"}}>Affiliate Dashboard</h1>
        {loading ? <h2>Loading...</h2> : <><h2>Total users: <strong style={{fontSize: "170%"}}>{emails[0].length + emails[1].length}</strong> user(s).
        <br/>Total money: <strong style={{fontSize: "170%"}}>${((emails[0].reduce((acc, curr) => acc + 24, 0) * 0.3) + (emails[1].reduce((acc, curr) => acc + 55, 0) * 0.3)).toFixed(2)}</strong><br/>(30% Recurring)</h2>
        <hr style={{width: "25%"}}/>
        <table
            id="affTable"
            style={{
              margin: "auto",
              marginBottom: "5vh",
              border: "1px solid gray",
            }}
          >
            <thead>
              <tr>
                <th>Email</th>
                <th>Plan Price</th>
              </tr>
            </thead>
            <tbody>
              {emails[0].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice}</td>
                    <td style={{textAlign: 'center'}}>$24/mo</td>
                  </tr>
                );
              })}
            </tbody>
          </table></>}
          <br/>
          <h2>Pioneers Bundle</h2>
          {loading ? <h2>Loading...</h2> : <>
        <table
            id="affTable"
            style={{
              margin: "auto",
              marginBottom: "5vh",
              border: "1px solid gray",
            }}
          >
            <thead>
              <tr>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {emails[1].map((emailPrice) => {
                return (
                  <tr>
                    <td>{emailPrice}</td>
                  </tr>
                );
              })}
            </tbody>
          </table></>}
          <br/><br/>
      </Box>
    </ThemeProvider>
  );
}
