import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Paper, Switch, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function base64ToText(base64) {
  return decodeURIComponent(escape(atob(base64)));
}

async function removeMarterTrackerAlert(db_id) {
  const queryParams = new URLSearchParams(window.location.search);
  const e = queryParams.get("e");

  const url = `https://server.getmarter.com/marter_tracker_remove_alert/${base64ToText(e)}/${db_id}/`;

  try {
    await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
    });
  } catch (error) {
    console.error('Error:', error);
  }
}

function isConditionMet(parameterValue, parameter, thresholdValue, thresholdSign, specificSellerId) {
  // Evaluate the alert condition
  let conditionMet = false;

  if (["Best Seller", "Popular Pick"].includes(parameter)) {
    conditionMet = parameterValue;
  } else if (thresholdSign === ">") {
    conditionMet = parameterValue > thresholdValue;
  } else if (thresholdSign === "<") {
    conditionMet = parameterValue < thresholdValue;
  } else if (thresholdSign === "==") {
    if (parameter === "BuyBox Seller Name") {
      conditionMet = parameterValue === specificSellerId;
    } else {
      conditionMet = parameterValue === thresholdValue;
    }
  } else if (thresholdSign === "!=") {
    if (parameter === "BuyBox Seller Name") {
      conditionMet = parameterValue !== specificSellerId;
    } else {
      conditionMet = parameterValue !== thresholdValue;
    }
  }

  return conditionMet
}

const ThresholdPopup = ({ open, handleClose, handleSave }) => {
  const [newThreshold, setNewThreshold] = React.useState("");

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change Threshold Value</DialogTitle>
      <DialogContent>
        <TextField
          label="New Threshold"
          type="number"
          fullWidth
          variant="outlined"
          value={newThreshold}
          sx={{ mt: 1 }}
          onChange={(e) => setNewThreshold(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button
          onClick={() => {
            handleSave(newThreshold);
            handleClose();
          }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function AlertCard({ props, setRender, setThreshPopUpAlertId }) {
  const attrWithSeller = ["Specific Seller Stock", "BuyBox Seller Name"];
  const conditionMet = isConditionMet(props.now_value, props.parameter, props.threshold_value, props.threshold_sign, props.specific_seller_id)

  return <Card sx={{
    width: 300,
    borderRadius: "16px",
    boxShadow: 10,
    backgroundColor: conditionMet ? "rgba(255, 201, 23, 0.075)" : "white",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  }}
  >
    {props.removed && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(128, 128, 128, 0.75)",
          zIndex: 1,
          color: "red"
        }}
      >DELETED</div>
    )}
    {conditionMet && (
      <div style={{
        backgroundColor: 'rgba(255, 201, 23, 1)',
        color: 'white',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      }}>
        <WarningAmberIcon style={{ marginRight: '6px' }} />
        ALERT TRIGGERED
      </div>
    )}
    <CardMedia
      component="img"
      height="140"
      image={props.image ? props.image : "/logo512.png"}
      alt="Product Image"
      style={{ objectFit: "contain", paddingTop: 10, borderRadius: "25px", mixBlendMode: "multiply" }}
    />
    <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <Tooltip title={props.name} arrow>
        <Typography variant="h5" component="div">
          {props.name ? (props.name.slice(0, 25) + "...") : "N/A"}
        </Typography>
      </Tooltip>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Item Id: <a target="_blank" rel="noopener noreferrer" href={"https://www.walmart.com/ip/" + props.item_id}>{props.item_id}</a>
      </Typography>
      <Typography variant="body2" color={conditionMet ? "red" : "text.secondary"}>
        Alert: <strong>{props.parameter} {props.threshold_sign} {props.threshold_value}</strong>
        {attrWithSeller.includes(props.parameter) && <><br />Seller: <a target="_blank" rel="noopener noreferrer" href={"https://www.walmart.com/seller/" + props.specific_seller_id}>{props.specific_seller_id}</a></>}
      </Typography>

      <Box sx={{
        border: '1px solid gray',
        borderRadius: '30px',
        display: 'inline-block',
        padding: '0% 10%',
        mt: 2
      }}>
        <Typography variant="subtitle2" sx={{ fontWeight: '600', borderBottom: '1px solid lightgray' }}>
          Value
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Was: <strong>{props.current_value}</strong> | Last: <strong>{props.now_value === true ? "true" : (props.now_value === false ? "false" : props.now_value)}</strong>
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="primary">
          Set Date: {props.date.split(" ")[0]}
        </Typography>
        <Typography variant="body2" color="primary">
          Note: <strong>{props.note}</strong>
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mt: 'auto' // Pushes the buttons to the bottom
      }}>
        <Button variant="contained" size="small" color='success' sx={{ mt: 2 }} onClick={() => window.open("https://www.histomart.com/kp?item_id=" + props.item_id)}>
          Full Data
        </Button>&emsp;
        <Button variant="contained" size="small" color='info' sx={{ mt: 2 }} onClick={() => setThreshPopUpAlertId(props.id)}>
          Edit
        </Button>&emsp;
        <Button variant="contained" size="small" color='warning' sx={{ mt: 2, backgroundColor: "red" }}
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this alert?") === true) {
              props.removed = true;
              removeMarterTrackerAlert(props.id);
              setRender((p) => (p + 1))
            }
          }}>
          Delete
        </Button>
      </Box>
    </CardContent>
  </Card>
}

export default function AlertsPage(props) {
  const [alerts, setAlerts] = React.useState([]);
  const [showTable, setShowTable] = React.useState(false);
  const [render, setRender] = React.useState(0);
  const [threshPopUpAlertId, setThreshPopUpAlertId] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const defaultTheme = createTheme({ palette: "light" });
  const attrWithSeller = ["Specific Seller Stock", "BuyBox Seller Name"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    try {
      const e = queryParams.get("e");
      fetch("https://server.getmarter.com/marter_tracker_get_alerts/" + base64ToText(e) + "/", {
        mode: "cors",
      }).then((response) => response.json()).then((data) => { setAlerts(data) });
    } catch { }
  }, [])


  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={'light'} setEmailModal={props.setEmailModal} />
      <Box render={render}>
        <h1 style={{ paddingTop: "15vh" }}>Alerts Page</h1>
        <Box display="flex" alignItems="center" style={{ justifyContent: "center", margin: "1%" }}>
          <Typography variant="body1">
            Card View
          </Typography>
          <FormControlLabel
            control={<Switch checked={showTable} onChange={(event) => {
              setShowTable(event.target.checked);
            }} />}
            label=""
            sx={{ margin: 1 }}
          />
          <Typography variant="body1">
            Table View
          </Typography>
        </Box>
        {showTable ? <TableContainer component={Paper} style={{ width: "95%", margin: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Item Id</TableCell>
                <TableCell>Alert</TableCell>
                <TableCell>Value Was</TableCell>
                <TableCell>Value Last</TableCell>
                <TableCell>Set Date</TableCell>
                <TableCell>Note</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((props) => (
                <TableRow key={props.id} style={{ backgroundColor: props.removed ? "gray" : (isConditionMet(props.now_value, props.parameter, props.threshold_value, props.threshold_sign, props.specific_seller_id) ? "rgba(255, 201, 23, 0.1)" : "white") }}>
                  <TableCell>
                    <img
                      height="40"
                      src={props.image ? props.image : "/logo512.png"}
                      alt="Product Image"
                      className="alertImgTable"
                      style={{ objectFit: "contain", paddingTop: 10, borderRadius: "25px", mixBlendMode: "multiply", height: 40, zIndex: "99999" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={props.name} arrow>
                      <Typography variant="p" component="div">
                        {props.name ? (props.name.slice(0, 45) + "...") : "N/A"}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      <a target="_blank" rel="noopener noreferrer" href={"https://www.walmart.com/ip/" + props.item_id}>{props.item_id}</a>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      Alert: <strong>{props.parameter} {props.threshold_sign} {props.threshold_value}</strong>
                      {attrWithSeller.includes(props.parameter) && <><br />Seller: <a target="_blank" rel="noopener noreferrer" href={"https://www.walmart.com/seller/" + props.specific_seller_id}>{props.specific_seller_id}</a></>}
                      {isConditionMet(props.now_value, props.parameter, props.threshold_value, props.threshold_sign, props.specific_seller_id) && <><div style={{
                        backgroundColor: 'rgba(255, 201, 23, 1)',
                        color: 'white',
                        fontWeight: 'bold',
                        display: 'flex',
                        width: "auto",
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '1px',
                      }}>
                        <WarningAmberIcon style={{ marginRight: '6px' }} />
                        ALERT TRIGGERED
                      </div></>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {props.current_value}
                  </TableCell>
                  <TableCell>
                    {props.now_value === true ? "true" : (props.now_value === false ? "false" : props.now_value)}
                  </TableCell>
                  <TableCell>
                    {props.date.split(" ")[0]}
                  </TableCell>
                  <TableCell>
                    {props.note}
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" size="small" color='success' onClick={() => window.open("https://www.histomart.com/kp?item_id=" + props.item_id)}>
                      Full Data
                    </Button>&emsp;
                    <Button variant="contained" size="small" color='info' onClick={() => setThreshPopUpAlertId(props.id)}>
                      Edit
                    </Button>&emsp;
                    <Button variant="contained" size="small" color='warning' sx={{ backgroundColor: "red" }}
                      onClick={() => {
                        if (window.confirm("Are you sure you want to delete this alert?") === true) {
                          props.removed = true;
                          removeMarterTrackerAlert(props.id);
                          setRender((p) => (p + 1))
                        }
                      }}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={alerts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer> : <Grid container spacing={4} sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {alerts.map((el, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
              <AlertCard props={el} setRender={setRender} setThreshPopUpAlertId={setThreshPopUpAlertId} />
            </Grid>
          ))}
        </Grid>}
      </Box>
      <br />
      <ThresholdPopup
        open={threshPopUpAlertId}
        handleClose={() => setThreshPopUpAlertId("")}
        handleSave={(v) => {
          const queryParams = new URLSearchParams(window.location.search);
          const e = queryParams.get("e");
          fetch("https://server.nepeto.com/marter_tracker_edit_alert/" + base64ToText(e) + "/" + threshPopUpAlertId + "/" + v + "/", {
            mode: "cors",
          })
          const aler = alerts.find(item => item.id === threshPopUpAlertId);
          if (aler) {
            aler.threshold_value = v;
          }
        }}
      />
    </ThemeProvider>
  );
}
