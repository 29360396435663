import * as React from "react";
import { Box, Avatar, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PioneersDashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const items = [
    { logo: "/logos/marter.png", title: "Marter", subtitle: "WM Product Analysis", link: "https://www.getmarter.com/" },
    { logo: "/logos/alerts.png", title: "Marter Alerts", subtitle: "WM Product Tracker Alert", link: "https://www.getmarter.com/" },
    { logo: "/logos/moa.png", title: "MOA", subtitle: "WM Sourcing Software", link: "https://www.marteroa.com/" },
    { logo: "/logos/falcon.png", title: "Inventory Falcon", subtitle: "Manage WM Inventory", link: "https://www.inventoryfalcon.com/" },
    { logo: "/logos/histomart.png", title: "Histomart", subtitle: "WM Historical Data", link: "https://www.histomart.com/" },
    { logo: "/logos/aniseller.png", title: "Aniseller", subtitle: "WM/AMZ CSV Scanner", link: "https://www.aniseller.com/" },
    { logo: "/logos/nepeto.png", title: "Nepeto RA OA / Nepeto 3.0", subtitle: "Sourcing Software for Amazon", link: "https://www.nepeto.com/" },
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        alignItems: "center",
        padding: isMobile ? "10px" : "5vh",
        marginTop: isMobile && "10%"
      }}
    >
      {/* Right Section */}
      <Box sx={{ width: isMobile ? "100%" : "50%", marginTop: isMobile ? "20px" : "0" }}>
        <Grid container spacing={1}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <img
                  src={item.logo}
                  style={{
                    width: isMobile ? "15%" : "25%",
                    marginRight: isMobile ? "5%" : "8%",
                    mixBlendMode: "multiply",
                  }}
                />
                <Stack>
                  <Typography variant="h6" style={{ textAlign: "left" }}>{item.title}</Typography>
                  <Typography variant="body2" color="textSecondary" style={{ textAlign: "left" }}>
                    {item.subtitle}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    marginLeft: "auto",
                    color: "red",
                    textAlign: "left",
                    fontSize: isMobile ? "12px" : "inherit",
                  }}
                >
                  <Button variant="contained" onClick={() => window.open(item.link)}>Click here to open!</Button>
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          width: isMobile ? "100%" : "50%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "40px",
          boxSizing: "border-box",
        }}
      >
        <Typography variant="h3" fontWeight="bold" gutterBottom>
          Hey Pioneer 👋
        </Typography>

        <Typography variant="h5" gutterBottom>
          Welcome to your dashboard!
        </Typography>

        <Typography variant="body1" sx={{ maxWidth: "800px", fontSize: "1.2rem" }}>
          Here, you'll find all the important links and information about webinars,
          recordings, and more. Make sure to save this page and check back daily.
        </Typography>

        <Typography variant="body1" sx={{ maxWidth: "800px", fontSize: "1.2rem", marginTop: "20px" }}>
          <a style={{ fontWeight: "bold" }} target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=K1_n5iCudmg">Launch Webinar Recording</a><br/>
          <a style={{ fontWeight: "bold", fontSize: "150%" }} target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/groups/530406235979080/permalink/683515007334868/">Training Schedule & Recordings</a><br/><br/>
          Meanwhile, if you've signed up as a Pioneer, please use the same email
          address for all tools. Please try to reset password ('Forgot Password') or sign up with the SAME email address, and try to access them, and if you run into any issues,
          let us know - we’ll grant you access ASAP.
        </Typography>

        <Typography
          variant="h6"
          sx={{ marginTop: "30px", fontWeight: "bold", color: "primary.main" }}
        >
          Need help? Email us at <br />
          <strong>info@getmarter.com</strong>
        </Typography>

        <Typography variant="body1" sx={{ marginTop: "20px", fontSize: "1.2rem" }}>
          We’re here to support you every step of the way. Let’s get you set up for success! 🚀
        </Typography>

        <Button
          variant="contained"
          color="success"
          size="large"
          sx={{
            marginTop: "40px",
            padding: "12px 24px",
            fontSize: "1.2rem",
            fontWeight: "bold",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={() => window.location.href = "/pioneers/start"}
        >
          Not a Pioneer yet? Click here to join the Pioneers
        </Button>
      </Box>
    </Box>
  );
}
